<template>
  <div>
    <line-chart
      v-if="loaded"
      :chart-data="getChartData"
      :options="getOptions"
    />
  </div>
</template>

<script>
import LineChart from '@/components/LineChart.js'
// import * as types from '@/store/mutation-types'
import helper from '@/utils/helper'
// import Vue from 'vue'

export default {
  name: 'RequestsChart',
  components: { LineChart },
  data() {
    return {
      loaded: false,
      // incr: 0,
      debounceTimer: null,
      chartdata: {},
      options: {},
      maxValueY: 1
    }
  },
  computed: {
    getRequestsStats() {
      return this.$store.getters['dashboard/requestsStats']
    },
    getChartData() {
      return this.chartdata
    },
    getOptions() {
      return this.options
    }
  },
  watch: {
    getRequestsStats(val) {
      this.loaded = false
      this.fillRequestsStats()
    }
  },
  async mounted() {
    this.$store.dispatch('dashboard/getRequestsStats').then(() => {
      this.fillRequestsStats()
    })

    // Reload data table
    this.$eventBus.$on('updateDashboardRequestsStats', (data) => {
      this.$store.commit('dashboard/UPDATE_DASHBOARD_REQUESTS_STATS', data)
    })
  },
  methods: {
    fillRequestsStats() {
      const requestsStats = this.getRequestsStats

      if (!helper.has(requestsStats, 'request')) {
        requestsStats.request = { success: {}, error: {} }
      }

      if (!helper.has(requestsStats, 'response')) {
        requestsStats.response = { success: {}, error: {} }
      }

      const requestSuccess = JSON.parse(JSON.stringify(requestsStats.request.success))
      const requestError = JSON.parse(JSON.stringify(requestsStats.request.error))

      const responseSuccess = JSON.parse(JSON.stringify(requestsStats.response.success))
      const responseError = JSON.parse(JSON.stringify(requestsStats.response.error))

      const dataSuccessRequests = []
      Object.keys(requestSuccess).forEach((x, y) => {
        if (this.maxValueY < requestSuccess[x]) {
          this.maxValueY = requestSuccess[x]
        }
        dataSuccessRequests.push({ x: new Date(x), y: requestSuccess[x] })
      })

      const dataErrorRequests = []
      Object.keys(requestError).forEach((x, y) => {
        if (this.maxValueY < requestError[x]) {
          this.maxValueY = requestError[x]
        }
        dataErrorRequests.push({ x: new Date(x), y: requestError[x] })
      })

      const dataSuccessResponses = []
      Object.keys(responseSuccess).forEach((x, y) => {
        if (this.maxValueY < responseSuccess[x]) {
          this.maxValueY = responseSuccess[x]
        }
        dataSuccessResponses.push({ x: new Date(x), y: responseSuccess[x] })
      })

      const dataErrorResponse = []
      Object.keys(responseError).forEach((x, y) => {
        if (this.maxValueY < responseError[x]) {
          this.maxValueY = responseError[x]
        }
        dataErrorResponse.push({ x: new Date(x), y: responseError[x] })
      })

      this.chartdata = {
        datasets: [
          {
            label: this.$t('Request success'),
            backgroundColor: '#D6E9C6',
            data: dataSuccessRequests
          },
          {
            label: this.$t('Response success'),
            backgroundColor: '#c6d8e9',
            data: dataSuccessResponses
          },
          {
            label: this.$t('Request error'),
            backgroundColor: '#FAEBCC',
            data: dataErrorRequests
          },
          {
            label: this.$t('Response error'),
            backgroundColor: '#EBCCD1',
            data: dataErrorResponse
          }
        ]
      }

      this.options = {
        animation: {
          duration: 250,
          easing: 'linear'
        },
        responsive: true,
        maintainAspectRatio: false,
        title: {
          fontFamily: 'Rubik',
          fontSize: 16,
          fontStyle: 'normal',
          display: true,
          text: this.$t('External Request/Response Statistics (Today)')
        },
        legend: {
          position: 'bottom',
          labels: {
            fontSize: 12,
            fontFamily: 'Rubik',
            fontStyle: 'normal'
          }
        },
        tooltips: {
          titleFontFamily: 'Rubik',
          bodyFontFamily: 'Rubik'
        },
        scales: {
          xAxes: [{
            ticks: {
              fontSize: 10,
              fontFamily: 'Rubik',
              source: 'data'
            },
            type: 'time',
            distribution: 'series',
            time: {
              unit: 'minute',
              stepSize: 60,
              round: true
            },
            scaleLabel: {
              display: true,
              fontFamily: 'Rubik',
              fontColor: '#a4a4a4',
              labelString: 'Request / Response event time (UTC)'
            }
          }],
          yAxes: [
            {
              stacked: false,
              ticks: {
                fontSize: 11,
                fontFamily: 'Rubik',
                userCallback: function (tick) {
                  return tick
                },
                beginAtZero: true,
                min: 0
                // stepSize: () => {
                //   Math.ceil(this.maxValueY / 10)
                // }
              },
              scaleLabel: {
                display: true,
                fontFamily: 'Rubik',
                fontColor: '#a4a4a4',
                labelString: 'Request / Response per min.',
                defaultFontFamily: 'sans-serif'
              }
            }
          ]
        }
      }

      this.loaded = true
    }
  }
}
</script>
