<template>
  <div>
    <doughnut-chart
      :chart-data="getChartData"
      :options="getOptions"
    />
  </div>
</template>

<script>
import DoughnutChart from '@/components/PieChart.js'

export default {
  name: 'MissingDataWidget',
  components: {
    DoughnutChart
  },
  data() {
    return {
      loaded: true,
      chartdata: {},
      options: {}
    }
  },
  computed: {
    getMissingDataCounters() {
      return this.$store.getters['dashboard/missingDataCounters']
    },
    getChartData() {
      return this.chartdata
    },
    getOptions() {
      return this.options
    }
  },
  async mounted() {
    await this.fillMissingDataCountersPie()
  },
  methods: {
    async fillMissingDataCountersPie() {
      await this.$store.dispatch('dashboard/getMissingDataCounters')

      const missingDataCounters = this.$store.getters['dashboard/missingDataCounters']

      const results = []
      for (const vk in missingDataCounters) {
        const v = missingDataCounters[vk]
        results.push(Number(v.value))
      }

      this.chartdata = {
        labels: [
          this.$t('dashboard.missingData.domainsWithoutProviders'),
          this.$t('dashboard.missingData.domainsWithoutServers'),
          this.$t('dashboard.missingData.domainsWithoutCloudflare'),
          this.$t('dashboard.missingData.successfullyConfiguredDomains')
        ],
        datasets: [
          {
            backgroundColor: ['#c6d8e9', '#EBCCD1', '#FAEBCC', '#D6E9C6'],
            borderColor: '#eee',
            hoverBorderColor: '#eee',
            data: results
          }
        ]
      }

      this.options = {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          fontFamily: 'Rubik',
          fontSize: 16,
          fontStyle: 'normal',
          display: true,
          text: 'Missing data chart'
        },
        legend: {
          position: 'bottom',
          labels: {
            fontSize: 12,
            fontFamily: 'Rubik',
            fontStyle: 'normal'
          }
        },
        tooltips: {
          bodyFontFamily: 'Rubik'
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
