<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col
        cols="12"
        sx="12"
        sm="12"
        md="12"
        lg="12"
      >
        <general-counters-widget />
      </v-col>
      <v-col
        cols="12"
        sx="12"
        sm="12"
        md="8"
        lg="8"
      >
        <!--missing-data-widget class="mb-7" /-->
        <div class="chart-wrapper">
          <requests-chart />
        </div>
      </v-col>
      <v-col
        cols="12"
        sx="12"
        sm="12"
        md="4"
        lg="4"
      >
        <div class="chart-wrapper">
          <missing-data-widget />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import MissingDataWidget from './MissingDataWidget'
import GeneralCountersWidget from './GeneralCountersWidget'
// import RequestsStats from './RequestsStats'
import RequestsChart from './RequestsChart'

export default {
  name: 'Dashboard',
  components: {
    MissingDataWidget,
    GeneralCountersWidget,
    // RequestsStats
    RequestsChart
  },
  metaInfo() {
    return {
      title: this.$store.getters['app/appTitle'],
      titleTemplate: `${this.$t('dashboard.meta.title')} — %s`
    }
  },
  data() {
    return {}
  },
  computed: {},
  async mounted() {},
  async created() {},
  methods: {}
}
</script>

<style lang="scss">
.chart-wrapper {
  margin-top: 15px;
  border: 1px solid #ebebeb;
  max-height: 500px;
  width: 100%;
}
</style>
