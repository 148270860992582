<template>
  <v-row
    ref="container"
    v-resize="onResize"
    justify="center"
  >
    <v-col
      v-for="(widget, i) of getWidgets"
      :key="i"
      cols="12"
      xs="12"
      sm="4"
      md="3"
      lg="3"
      class="general-counters-widget"
    >
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card
            :elevation="hover ? 6 : 3"
            class="mx-auto pa-0"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="title font-weight-light mb-2">
                  {{ $t(`dashboard.genCountWidget.${widget.label}`) }}
                  <v-divider class="mt-3" />
                </v-list-item-title>
                <v-list-item-subtitle class="subheading font-weight-light grey--text number">
                  <p class="number">
                    {{ widget.value }}
                  </p>
                  {{ $t(`dashboard.genCountWidget.${widget.unit}`) }}
                </v-list-item-subtitle>
                <v-icon
                  v-show="containerWidth !== 450"
                  color="#b5d4f3"
                  size="72"
                >
                  mdi-{{ widget.icon }}
                </v-icon>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </template>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>

import anime from 'animejs/lib/anime.es.js'

export default {
  name: 'GeneralCountersWidget',
  data() {
    return {
      containerWidth: 0,
      widgets: [
        { key: 'domainProviders', label: 'domainsRegistrars', icon: 'webpack', value: 0, unit: 'providers' },
        { key: 'domains', label: 'domainsInTheSystem', icon: 'web', value: 0, unit: 'domains' },
        { key: 'hostingProviders', label: 'hostingProviders', icon: 'server-security', value: 0, unit: 'providers' },
        { key: 'hostingServers', label: 'hostingServers', icon: 'server-network', value: 0, unit: 'servers' },
        { key: 'cloudflareAccounts', label: 'cloudflareAccounts', icon: 'cloud-outline', value: 0, unit: 'accounts' },
        { key: 'affiliates', label: 'affiliatesAccounts', icon: 'handshake-outline', value: 0, unit: 'accounts' },
        { key: 'users', label: 'systemUsersAccounts', icon: 'account-multiple-outline', value: 0, unit: 'users' },
        { key: 'admins', label: 'systemAdminsAccounts', icon: 'account-cog', value: 0, unit: 'admins' }
      ]
    }
  },
  computed: {
    getWidgets() {
      return this.widgets
    }
  },
  async mounted() {
    await this.$store.dispatch('dashboard/getGeneralCounters')
    this.initWidgets()
    this.onResize()
  },
  methods: {
    initWidgets() {
      if (this.widgets.length < 1) {
        return false
      }
      const generalCounters = this.$store.getters['dashboard/generalCounters']
      for (const widgetKey in this.widgets) {
        const widgetData = this.widgets[widgetKey]
        if ({}.propertyIsEnumerable.call(generalCounters, `${widgetData.key}`)) {
          const obj = { n: 0 }
          anime({
            targets: obj,
            n: generalCounters[`${widgetData.key}`],
            round: 1,
            duration: 1000,
            easing: 'linear',
            update: () => {
              this.widgets[widgetKey].value = Number(obj.n)
            }
          })
        }
      }
    },
    onResize() {
      try {
        if (this.$refs.container && 'clientWidth' in this.$refs.container) {
          this.containerWidth = this.$refs.container.clientWidth
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss">
p.number {
  font-size: 3rem !important;
  font-weight: 505 !important;
  margin-bottom: 0 !important;
}
.general-counters-widget .v-list-item__content {
  position: relative;
}
.general-counters-widget .v-icon {
  color: #55aaff;
  position: absolute !important;
  right: 0;
  bottom: 15px;
  margin: 0;
}
</style>
